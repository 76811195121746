.filter-app {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    background-color: antiquewhite;
    margin: 10px;


}

.filter {
    margin: 10px;
    min-width: 200px;
    max-width: 400px;
}

.selected-countries-option,
.selected-option {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: auto;
}

.selected-display {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: aliceblue;
    min-width: 120px;
    max-width: 240px;
    width: 300px;
}

.selected-countries-deselect,
.selected-deselect {
    margin-left: 5px;
}

.label {
    font-size: 15px;
}

.multiselect {
    width: 250px;
}

.checkbox-div {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;
}
.checkbox {
    width: 30px;
    height: 30px;
}
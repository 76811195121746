.map-container {
  /* display: flex; */
  justify-content: center;
  width: 100%;
  height: auto;
  /* flex-direction: row; */
  align-items: center;
  background-color: bisque;
  /* flex-wrap: wrap; */

}
.Map {
  width: 100%;
  max-width: 1500px;
  height: auto;
  background-color: #d5e6d69a;
  viewport-fit: auto;
}

body {
  margin: 0;
  /* overflow: hidden; */
  font-family: 'Poppins', sans-serif;
}

pre {
  font-size: 7em;
}

.land {
  fill: #85eb8f8a;
}

.sphere {
  fill: #fbfbfb;
}

.interiors {
  fill: none;
  stroke: #13cced;
}

.graticules {
  fill: none;
  stroke: #ececec;
}

circle {
  fill: #137B80;
  opacity: 0.3;
}

.selected-coord {
  fill: red;
  opacity: 0.5;
  r: 15px;
}
.crag-coord {
  fill:#070ef1;
  opacity:0.6;
  r:12px;
}

.crux-markers {
    writing-mode: horizontal-tb;
}

.resistance-markers {
    writing-mode: vertical-lr;
}

.crux-slider {
    appearance: slider-vertical;
    writing-mode: bt-lr;
    -webkit-appearance: slider-vertical;
}

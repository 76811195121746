
.legend-grade-point{
  r: 10px;
  opacity: 80%;
}

.selected-grade-point {
  stroke-width: 1px;
  stroke: #f4f1f1;
  r: 10px;
  opacity: 0.9;
}

.selected-data-point {
  stroke-width: 3px;
  stroke: #393939;
  r: 15px;
  opacity: 1;
}


/* .unselected-data-point,  */
.unselected-grade-point {
  r: 5px;
  opacity: 0.5;
}

/* .noselection-data-point,  */
.noselection-grade-point {
  r: 6px;
  opacity: 0.7;
}

.forecast {
  /* stroke: rgba(119, 115, 115, 0.105); */
  stroke-width: 5vh;
  opacity: 30%;

}

.grade20 {
  fill: gold;
  stroke: gold;
}

.grade24 {
  fill: #f44336;
  stroke: #f44336;
}

.grade25 {
  fill: #744700;
  stroke: #744700;
}

.grade26 {
  fill: #ce7e00;
  stroke: #ce7e00;
}

.grade27 {
  fill: #8fce00;
  stroke: #8fce00;
}

.grade28 {
  fill: #cc2983;
  stroke: #cc2983;
}

.grade29 {
  fill: #167e1a;
  stroke: #167e1a;
}

.grade30 {
  fill: #9f4632;
  stroke: #9f4632;
  /* r:20px; */
}

.grade31 {
  fill: #1b00c9;
  stroke: #1b00c9;
}

.grade32 {
  fill: #cc0000;
  stroke: #cc0000;
}

.grade33 {
  fill: #e69138;
  stroke: #e69138;
}

.grade34 {
  fill: #f1c232;
  stroke: #f1c232;
}

.grade35 {
  fill: #6aa84f;
  stroke: #6aa84f;
}

.grade36 {
  fill: #45818e;
  stroke: #45818e;
}

.grade37 {
  fill: #440154ff;
  stroke: #440154ff;
}

.grade38 {
  fill: #21908dff;
  stroke: #21908dff;
}

.grade39 {
  fill: #fde725ff;
  stroke: #fde725ff;
}